/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "@fontsource/roboto"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "swiper/css"
import "swiper/css/navigation"

import * as React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import { config } from "./src/utils/config"
import { ThemeProvider } from "./src/context/ThemeContext"

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider>
      <GoogleReCaptchaProvider reCaptchaKey={config.CAPTCHA_KEY}>
        {element}
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  )
}
