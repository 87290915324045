import React, { useState } from "react"

const defaultState = {
  titleModal: "",
  isOpenModal: false,
  setIsOpenModal: () => {},
  setTitleModal: () => {},
}
const ThemeContext = React.createContext(defaultState)

const ThemeProvider = props => {
  const [titleModal, setTitleModal] = useState(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  return (
    <ThemeContext.Provider
      value={{
        titleModal,
        isOpenModal,
        setIsOpenModal: value => setIsOpenModal(value),
        setTitleModal: value => setTitleModal(value),
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext

export { ThemeProvider }
